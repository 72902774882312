.product-card {
  display: inline-block;
  width: 18rem;
  // max-width: 80%;

  h1.brand-name {
    font-size: 1.8em;
  }

  img.brand-logo {
    // max-height: 5vw;
    // width: 100%;
  }
}

.product-card:hover {
  cursor: pointer;
  background-color: $light !important;
}

.card-columns {
  column-count: 1;

  @include media-breakpoint-only(md) {
    column-count: 2;
  }

  @include media-breakpoint-only(lg) {
    column-count: 2;
  }

  @include media-breakpoint-only(xl) {
    column-count: 3;
  }
}